<!-- eslint-disable -->
<template>
  <EuiBlock :title="$i18n.t('linkedOrganizations.block.info')">
    <form>
      <EuiGrid>
        <template v-for="(field, i) in availableFields">
          <template v-if="field.name==='email'">
            <EuiGridRow>
              <EuiGridColumn>
                <ValidationProvider :name="$i18n.t('signatory.table.email')" rules="required|email" v-slot="{ errors, valid, invalid }">
                  <EuiTextField :label="$i18n.t('signatory.table.email')"
                                type="text"
                                v-model="localSignatory.email"
                                :invalid="errors.length > 0"
                                :warning="invalid && errors.length === 0"
                                :errorMessage="errors[0]"
                                :placeholder="$i18n.t('signatory.form.email.placeholder')"/>
                </ValidationProvider>
              </EuiGridColumn>
              <EuiGridColumn v-if="availableFields[i+1] !== undefined && availableFields[i+1].type !== 'file'">
                <EuiTextField :key="'eui-textfield-'+i+1" :invalid="availableFields[i+1].invalid">
                  <EuiLabel :key="'eui-label-'+i+1" >{{ availableFields[i+1].text }}</EuiLabel>
                  <EuiInput v-if="availableFields[i+1].type === 'text'" :key="'eui-input-'+i" v-model="localSignatory[availableFields[i+1].name]" :name="availableFields[i+1].name"
                            :type="availableFields[i+1].type" :placeholder="availableFields[i+1].placeholder" :required="availableFields[i+1].required" />
                  <EuiSelect v-else-if="availableFields[i+1].type === 'select'" v-model="localSignatory[availableFields[i+1].name]" :key="'eui-select-'+i">
                    <option v-for="option in availableFields[i+1].options" :value="option.value" :key="option.value">{{ option.text }}</option>
                  </EuiSelect>
                </EuiTextField>
              </EuiGridColumn>
            </EuiGridRow>
          </template>
          <template v-else-if="field.type !== 'file'">
            <EuiGridRow v-if="i % 2 === 0">
              <EuiGridColumn>
                <EuiTextField :key="'eui-textfield-'+i" :invalid="field.invalid">
                  <EuiLabel :key="'eui-label-'+i" >{{ field.text }}</EuiLabel>
                  <EuiInput v-if="field.type === 'text'" :key="'eui-input-'+i" v-model="localSignatory[field.name]" :name="field.name"
                            :invalid="field.invalid" :type="field.type" :placeholder="field.placeholder" :required="field.required" />
                  <EuiSelect v-else-if="field.type === 'select'" v-model="localSignatory[field.name]" :key="'eui-select-'+i">
                    <option v-for="option in field.options" :value="option.value" :key="option.value">{{ option.text }}</option>
                  </EuiSelect>
                  <p class="eui-m-textField__hint" v-if="field.errorMessage">{{ field.errorMessage }}</p>
                </EuiTextField>
              </EuiGridColumn>
              <EuiGridColumn v-if="availableFields[i+1] !== undefined && availableFields[i+1].type !== 'file'">
                <EuiTextField :key="'eui-textfield-'+i+1" :invalid="availableFields[i+1].invalid">
                  <EuiLabel :key="'eui-label-'+i+1" >{{ availableFields[i+1].text }}</EuiLabel>
                  <EuiInput v-if="availableFields[i+1].type === 'text'" :key="'eui-input-'+i" v-model="localSignatory[availableFields[i+1].name]" :name="availableFields[i+1].name"
                            :type="availableFields[i+1].type" :placeholder="availableFields[i+1].placeholder" :required="availableFields[i+1].required" />
                  <EuiSelect v-else-if="availableFields[i+1].type === 'select'" v-model="localSignatory[availableFields[i+1].name]" :key="'eui-select-'+i">
                    <option v-for="option in availableFields[i+1].options" :value="option.value" :key="option.value">{{ option.text }}</option>
                  </EuiSelect>
                </EuiTextField>
              </EuiGridColumn>
            </EuiGridRow>
          </template>
        </template>

        <EuiGridRow class="eui-u-h-24">
          <EuiGridColumn>
            <EuiTextField>
              <EuiLabel>{{ $t("signatory.form.logo") }}</EuiLabel>
              <div class="mt-1">
                <EuiButton @click.prevent="$refs.logoUploader.click()"
                           variant="raised"
                           size="small"
                           color="primary">
                  {{ $t('button.upload') }}
                </EuiButton>
              </div>
            </EuiTextField>
          </EuiGridColumn>
          <EuiGridColumn>
            <div class="es-signatory__logo"

                 v-if="checkDisplayLogo(localSignatory)">
              <img :src="'data:image/png;base64,' + localSignatory.logo"/>
              <div class="es-signatory__logo__hover-panel">
                <EuiIcon name="more_vert"/>
                <EuiButton size="large" variant="text" @click="localSignatory.logo = undefined"  iconOnly color="primary">
                  <EuiIcon outlined name="delete"/>
                </EuiButton>
              </div>
            </div>
            <input ref="logoUploader"
                   style="display: none"
                   type="file"
                   accept="image/png, image/jpeg"
                   @click="resetLogoUploader"
                   @change="uploadFile($event.target.files[0])">
          </EuiGridColumn>
        </EuiGridRow>
      </EuiGrid>
    </form>
  </EuiBlock>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'Signatory',
  props: {
    options: {
      type: Object,
    },
    signatory: {
      type: Object,
    },
  },
  computed: {
    localSignatory() {
      return this.signatory.signatory;
    },
    fileFields() {
      return this.fields.filter((x) => this.formFieldIsAvailable(x.checkField))
        .filter((e) => e.type === 'file');
    },
    availableFields() {
      return this.fields.filter((x) => this.formFieldIsAvailable(x.checkField));
    },
  },
  data() {
    return {
      fields: [
        {
          name: 'entityType',
          text: Vue.$i18n.t('signatory.form.entity'),
          placeholder: undefined,
          errorMessage: '',
          type: 'select',
          options: [
            { text: Vue.$i18n.t('signatory.form.entity.person'), value: 'person' },
            { text: Vue.$i18n.t('signatory.form.entity.society'), value: 'society' },
            { text: Vue.$i18n.t('signatory.form.entity.public'), value: 'organism' },
          ],
          checkField: 'signerCreationFormDefineEntityType',
          required: true,
        },
        {
          name: 'firstname',
          text: Vue.$i18n.t('signatory.table.firstname'),
          placeholder: Vue.$i18n.t('signatory.table.firstname'),
          errorMessage: '',
          type: 'text',
          required: true,
        },
        {
          name: 'lastname',
          text: Vue.$i18n.t('signatory.table.name'),
          placeholder: Vue.$i18n.t('signatory.table.name'),
          errorMessage: '',
          type: 'text',
          required: true,
        },
        {
          name: 'email',
          text: Vue.$i18n.t('signatory.table.email'),
          placeholder: Vue.$i18n.t('signatory.form.email.placeholder'),
          errorMessage: '',
          type: 'text',
          required: true,
        },
        {
          name: 'mobilePhone',
          text: Vue.$i18n.t('signatory.form.phone'),
          placeholder: Vue.$i18n.t('signatory.form.phone.placeholder'),
          errorMessage: '',
          type: 'text',
          regExp: '^[+]?[0-9]*$',
          checkField: 'signerCreationFormDefineMobilePhone',
          required: false,
        },
        {
          name: 'address',
          text: Vue.$i18n.t('signatory.form.address'),
          placeholder: Vue.$i18n.t('signatory.form.address'),
          errorMessage: '',
          type: 'text',
          checkField: 'signerCreationFormDefineAddress',
          required: false,
        },
        {
          name: 'postalCode',
          text: Vue.$i18n.t('signatory.form.zip'),
          placeholder: Vue.$i18n.t('signatory.form.zip.placeholder'),
          errorMessage: '',
          type: 'text',
          checkField: 'signerCreationFormDefineAddress',
          required: false,
        },
        {
          name: 'town',
          text: Vue.$i18n.t('signatory.form.town'),
          placeholder: Vue.$i18n.t('signatory.form.town'),
          errorMessage: '',
          type: 'text',
          checkField: 'signerCreationFormDefineAddress',
          required: false,
        },
        {
          name: 'login',
          text: Vue.$i18n.t('login.username'),
          placeholder: Vue.$i18n.t('login.username'),
          errorMessage: '',
          type: 'text',
          checkField: 'signerCreationFormDefinePassword',
          required: false,
        },
        {
          name: 'password',
          text: Vue.$i18n.t('login.password'),
          placeholder: Vue.$i18n.t('login.password'),
          errorMessage: '',
          type: 'password',
          checkField: 'signerCreationFormDefinePassword',
          required: false,
        },
        {
          name: 'service',
          text: Vue.$i18n.t('signatory.form.jobSector'),
          placeholder: Vue.$i18n.t('signatory.form.jobSector'),
          errorMessage: '',
          type: 'text',
          checkField: 'signerCreationFormDefineService',
          required: false,
        },
        {
          name: 'function',
          text: Vue.$i18n.t('signatory.form.jobRole'),
          placeholder: Vue.$i18n.t('signatory.form.jobRole'),
          errorMessage: '',
          type: 'text',
          checkField: 'signerCreationFormDefineFunction',
          required: false,
        },
        {
          name: 'reference',
          text: Vue.$i18n.t('signatory.form.reference'),
          placeholder: Vue.$i18n.t('signatory.form.reference'),
          errorMessage: '',
          type: 'text',
          checkField: 'signerCreationFormDefineReference',
          required: false,
        },
        {
          name: 'logo',
          text: Vue.$i18n.t('signatory.form.logo'),
          placeholder: undefined,
          errorMessage: '',
          type: 'file',
          required: false,
        },
      ],
    };
  },
  methods: {
    resetLogoUploader() {
      this.$refs.logoUploader.value = '';
    },
    validateFormFields() {
      let isValid = true;
      this.availableFields.forEach((element) => {
        if (element.regExp) {
          this.$set(element, 'invalid', !new RegExp(element.regExp).test(this.localSignatory[element.name]));
          isValid = !element.invalid;
        }
      });
      return isValid;
    },
    /**
     * used to set a field invalid or not
     * @param fieldName - name of the field
     * @param isInvalid - true if the field is on error
     * @param reason - reason if the field is invalid
     */
    setInvalidField(fieldName, isInvalid, reason = '') {
      const field = this.availableFields.find((item) => item.name === fieldName);
      if (field) {
        this.$set(field, 'invalid', isInvalid);
        this.$set(field, 'errorMessage', reason);
      }
    },
    // check in profile options if the input form field is available
    formFieldIsAvailable(value) {
      if (this.options.options && this.options.options[value]) {
        return parseInt(this.options.options[value].value);
      }
      return value === undefined;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.result);
      });
    },
    uploadFile(file) {
      this.getBase64(file).then((res) => {
        this.localSignatory.logo = res.split(';')[1].split(',')[1];
      });
    },
    checkDisplayLogo(signatory) {
      if (signatory.logo !== undefined) {
        if (signatory.logo !== null) {
          return !signatory.logo.startsWith('datas/');
        }
        return true;
      }
      return false;
    },
  },
};
</script>
