<template>
  <EuiBlock :title="$i18n.t('linkedOrganizations.block.title')">
    <template v-slot:actions>
      <EuiButton variant="text" color="primary" @click="addOrganizationsModal">{{$t('button.add')}}</EuiButton>
    </template>
    <EuiHeading v-if="this.selectedOrganizations.length === 0" :level="3"><b>{{$t('linkedOrganizations.noOrg')}}</b></EuiHeading>
    <div v-else v-for="localOrg in this.selectedOrganizations" v-bind:key="'euiHeading'+localOrg.organizationId" class="eui-m-gridRow -justify-between">
      {{ localOrg.name }} - {{ localOrg.siret }}<EuiButton color="primary" iconOnly @click="removeLinkedOrg(localOrg)"><EuiIcon name="delete" /></EuiButton>
    </div>
    <EuiDialog size="large" :style="styleModal" @onClose="removeOrganizationsModal" :open="true">
      <template v-slot:header>{{$t('linkedOrganizations.add')}}</template>
      <div class="tableTopBar"></div>
      <div v-show="this.filters.length > 0">
        <div class="eui-u-flex eui-u-items-center eui-u-ml-6">
          <EuiLabel>{{ $t('filterBar.title') }}</EuiLabel>
          <EuiIcon name="filter" class="ml-2"></EuiIcon>
          <EuiChip v-for="filter in filters"
                   closable
                   @click:close="removeFilter(filter)"
                   :key="filter.name"
                   class="eui-u-text-white eui-u-ml-4">
            <template #text>
              <span class="eui-u-font-bold eui-u-text-white">{{ $t(`filterBar.chips.title.${filter.name}`) }}:</span>
              <span class="eui-u-text-white">{{ filter.value }}</span>
            </template>
          </EuiChip>
          <EuiChip :text="$t('filterBar.button.reset')"
                   clickable outlined
                   class="eui-u-ml-4"
                   @click:chip="resetFilters"/>
        </div>
        <EuiDivider secondary class="m-1"/>
      </div>
      <EuiTable v-if="organizations.length">
        <EuiTableHeader>
          <EuiTableRow>
            <!-- checkbox -->
            <EuiTableHead class="pt-0 pb-0"></EuiTableHead>
            <!-- name -->
            <EuiTableHead class="pt-0 pb-0" >
              {{ $t('signworkflows.create.dialog.organizations.table.name') }}
              <EuiDropdownMenu ref="ddNameFilter" :closeOnClickInside="false">
                <EuiCardFilter :searchPlaceholder="$t('searchBar.placeholder')"
                               :buttonText="$t('cardFilter.button.filter')"
                               v-model="availableFilters.name.searchFieldValue"
                               @click:search="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()"
                               @enter:searchBar="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()"
                               @click:filter="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()">
                </EuiCardFilter>
                <template #anchor><EuiButton iconOnly><EuiIcon name="more_vert" color="primary"/></EuiButton></template>
              </EuiDropdownMenu>
            </EuiTableHead>
            <!-- siret -->
            <EuiTableHead class="pt-0 pb-0" >
              {{ $t('signworkflows.create.dialog.organizations.table.siret') }}
              <EuiDropdownMenu ref="ddSiretFilter" :closeOnClickInside="false">
                <EuiCardFilter :searchPlaceholder="$t('searchBar.placeholder')"
                               :buttonText="$t('cardFilter.button.filter')"
                               v-model="availableFilters.siret.searchFieldValue"
                               @click:search="filterOnColumn(availableFilters.siret, availableFilters.siret.searchFieldValue); $refs.ddSiretFilter.doClose()"
                               @enter:searchBar="filterOnColumn(availableFilters.siret, availableFilters.siret.searchFieldValue); $refs.ddSiretFilter.doClose()"
                               @click:filter="filterOnColumn(availableFilters.siret, availableFilters.siret.searchFieldValue); $refs.ddSiretFilter.doClose()">
                </EuiCardFilter>
                <template #anchor><EuiButton iconOnly><EuiIcon name="more_vert" color="primary"/></EuiButton></template>
              </EuiDropdownMenu>
            </EuiTableHead>

            <EuiTableHead class="pt-0 pb-0">
              {{ $t('signworkflows.create.dialog.organizations.table.email') }}
            </EuiTableHead>

            <EuiTableHead class="pt-0 pb-0">
              {{ $t('signworkflows.create.dialog.organizations.table.city') }}
            </EuiTableHead>
          </EuiTableRow>
        </EuiTableHeader>
        <EuiTableBody>

          <EuiTableRow v-for="item in organizations" :key="item.id">
            <!-- checkbox -->
            <EuiTableCell dataType="input">
              <EuiCheckbox :value="item" v-model="temporarySelectedOrganizations"/>
            </EuiTableCell>
            <!-- name -->
            <EuiTableCell>{{ item.name }}</EuiTableCell>
            <EuiTableCell>{{ item.siret }}</EuiTableCell>
            <EuiTableCell>{{ item.contactEmail }}</EuiTableCell>
            <EuiTableCell>{{ item.zipCode }} {{ item.townName }}</EuiTableCell>
          </EuiTableRow>

        </EuiTableBody>
      </EuiTable>
      <!-- pagination -->
      <EuiPagination v-if="organizations.length"
                     class="mt-4"
                     :value="pagination.current"
                     @change="goToPage"
                     :paginationText="$t('pagination.text')"
                     :totalPages="pagination.total"/>
      <template v-slot:footer>
        <EuiButton color="primary" @click="removeOrganizationsModal">{{$t('button.cancel')}}</EuiButton>
        <EuiButton color="primary" variant="raised" @click="saveOrgSelection">{{$t('button.confirm')}}</EuiButton>
      </template>
    </EuiDialog>
  </EuiBlock>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LinkedOrganizations',
  props: {
    localSignatoryId: {
      type: String,
    },
  },
  data() {
    return {
      styleModal: {
        display: 'none',
        paddingLeft: '10rem',
        alignContent: 'center',
      },
      organizations: [],
      temporarySelectedOrganizations: [],
      selectedOrganizations: [],
      fetchedLinkedOrgs: [],
      searchParams: {},
      availableFilters: {
        name: {
          type: 'text', name: 'name', value: '', searchParamName: 'name', searchFieldValue: '',
        },
        siret: {
          type: 'text', name: 'siret', value: '', searchParamName: 'siret', searchFieldValue: '',
        },
      },
      filters: [],
    };
  },
  computed: {
    ...mapGetters({
      pagination: 'ModuleEdocSign/organizations/pagination',
      storeSearchParams: 'ModuleEdocSign/organizations/searchParams',
    }),
  },
  methods: {
    ...mapActions({
      searchOrganizations: 'ModuleEdocSign/organizations/search',
      getLinkedOrganizations: 'ModuleEdocSign/organizations/findBySignatoryId',
    }),
    addOrganizationsModal() {
      this.temporarySelectedOrganizations = [...this.selectedOrganizations];
      this.styleModal.display = 'flex';
    },
    removeOrganizationsModal() {
      this.styleModal.display = 'none';
    },
    removeLinkedOrg(organization) {
      this.selectedOrganizations.splice(this.selectedOrganizations.findIndex((org) => org.organizationId === organization.organizationId), 1);
      this.affectSignToOrganisations();
    },
    affectSignToOrganisations() {
      this.$emit('affectSignatoryToOrg', {
        fetchedOrgs: this.orgIdArrayTransform(this.fetchedLinkedOrgs),
        localOrgs: this.orgIdArrayTransform(this.selectedOrganizations),
      });
    },
    saveOrgSelection() {
      this.selectedOrganizations = [...this.temporarySelectedOrganizations];
      this.affectSignToOrganisations();
      this.removeOrganizationsModal();
    },
    orgIdArrayTransform(arr) {
      return Array.from(arr, (org) => org.organizationId);
    },
    organizationArrayTransform(organizations) {
      const newArrOrg = [];
      organizations.forEach((org) => {
        newArrOrg.push({
          organizationId: org.organizationId,
          name: org.issuerName ? org.issuerName : org.name,
          siret: org.siret,
          contactEmail: org.contactEmail,
          zipCode: org.zipCode,
          townName: org.townName,
        });
      });
      return newArrOrg;
    },
    searchOrgs() {
      this.searchParams.params = [];
      this.filters.forEach((filter) => {
        this.searchParams.params.push({
          paramName: filter.name, paramValue: filter.value,
        });
      });
      this.searchOrganizations(this.searchParams).then((response) => {
        this.organizations = this.organizationArrayTransform(response.organizations);
      });
    },
    goToPage(page) {
      this.searchParams.offset = (page - 1) * this.searchParams.limit;
      this.searchOrgs();
    },
    filterOnColumn(filter, filterValue) {
      filter.value = filterValue;
      this.addFilter(filter);
      this.availableFilters.name.searchFieldValue = '';
    },
    addFilter(filterToAdd) {
      this.filters = this.filters.filter((filter) => filter.name !== filterToAdd.name);
      this.filters.push(filterToAdd);
      this.searchOrgs();
    },
    removeFilter(filterToRemove) {
      this.filters = this.filters.filter((filter) => filter.name !== filterToRemove.name);
      this.searchOrgs();
    },
    resetFilters() {
      this.filters = [];
      this.searchOrgs();
    },
  },
  mounted() {
    this.searchParams = JSON.parse(JSON.stringify(this.storeSearchParams));
    this.searchOrganizations(this.searchParams).then((response) => {
      this.organizations = this.organizationArrayTransform(response.organizations);
    });
    // Gestion du rechargement
    if (this.localSignatoryId === 'create') {
      this.fetchedLinkedOrgs = [];
    } else if (this.localSignatoryId !== '') {
      this.getLinkedOrganizations(this.localSignatoryId).then((response) => {
        this.fetchedLinkedOrgs = this.organizationArrayTransform(response.organizations);
        this.selectedOrganizations = this.organizations.filter((org) => response.organizations.find((linkedOrg) => linkedOrg.organizationId === org.organizationId));
        this.temporarySelectedOrganizations = this.organizations.filter((org) => response.organizations.find((linkedOrg) => linkedOrg.organizationId === org.organizationId));
      });
    } else {
      this.$forceUpdate();
    }
  },
};
</script>

<style scoped>
  .marginButton {
    margin-top: 20px;
  }
</style>
